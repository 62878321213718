import React, { useState, useEffect } from 'react';
import { format, startOfWeek, addDays, addWeeks, differenceInHours } from 'date-fns';
import { de } from 'date-fns/locale';
import { toInt } from 'validator';

const ShiftPlanCalender = ({ includeWeekend = false , shiftPlan = null, setShiftPlan, setChangedStates, machineId}) => {
    const [localShiftPlan, setLocalShiftPlan] = useState(shiftPlan);
    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState({ time_from: '', time_to: '', user_id: '' });
    const [dayIndexForNewSlot, setDayIndexForNewSlot] = useState(null);
    
    const [editingField, setEditingField] = useState(null); // To track which field is being edited (format: { id, field })

    const generateFakeId = () => {
        // Extract existing IDs
        const existingIds = localShiftPlan.map(slot => slot.id || slot.fakeID);
    
        // Separate fake IDs and regular IDs
        const fakeIds = existingIds.filter(id => typeof id === 'string' && id.startsWith('fake-'));
        const regularIds = existingIds.filter(id => typeof id === 'string' && !id.startsWith('fake-'));
    
        let newFakeId;
    
        if (fakeIds.length > 0) {
            // Increment the highest fake ID if it exists
            const highestFakeId = Math.max(...fakeIds.map(id => parseInt(id.replace(/fake-/, ''), 10)));
            newFakeId = `fake-${highestFakeId + 1}`;
        } else {
            // If no fake IDs, take the highest regular ID
            const highestRegularId = Math.max(0, ...regularIds.map(id => parseInt(id, 10)));
            newFakeId = `fake-${highestRegularId + 1}`; // Start with next fake ID
        }
    
        return newFakeId; // Generate a new fakeID
    };

    const handleInputChange = (e, slotId, field) => {
        const value = e.target.value;
        setLocalShiftPlan((prev) => 
            prev.map((slot) => 
                (slot.id || slot.fakeID) === (slotId || slot.fakeID) 
                    ? { ...slot, [field]: field.includes('time') ? `${value}:00` : value } 
                    : slot
            )
        );
    }

    const handleEditComplete = () => {
        setEditingField(null); // Reset editing state
        setChangedStates(true); // Mark state as changed
    };

    // Handle key press (like pressing Enter) to finish editing
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleEditComplete();
        }
    };

    // Use useEffect to sync the local state with props when they change
    useEffect(() => {
        setLocalShiftPlan(shiftPlan);
    }, [shiftPlan]);
    
    // State for tracking the current week offset from today (0 = this week)
    const [weekOffset, setWeekOffset] = useState(0);

    // Function to generate the days for the current week (based on weekOffset)
    const generateWeekDays = () => {
        const start = startOfWeek(addWeeks(new Date(), weekOffset), { weekStartsOn: 1 }); // Monday as start
        const days = Array.from({ length: includeWeekend ? 7 : 5 }, (_, i) => addDays(start, i));
        return days;
    };

    // Go to the previous or next week
    const goToPreviousWeek = () => setWeekOffset((prev) => prev - 1);
    const goToNextWeek = () => setWeekOffset((prev) => prev + 1);

    // Generate the week days based on the current week offset
    const weekDays = generateWeekDays();

    const [calendarWidth, setCalendarWidth] = useState(0);
    useEffect(() => {
        const updateCalendarWidth = () => {
            const calendarElement = document.getElementById('calendar');
            if (calendarElement) {
                setCalendarWidth(calendarElement.clientWidth);
            }
        };

        updateCalendarWidth(); // Set initial width
        window.addEventListener('resize', updateCalendarWidth);

        return () => {
            window.removeEventListener('resize', updateCalendarWidth);
        };
    }, []);
    const widthStyle = {
        width: calendarWidth  < (includeWeekend ? 1000 : 720) 
            ? '100%' 
            : (calendarWidth - 16) / (includeWeekend ? 7 : 5),
        transition: 'width 0.3s ease' // Optional: adds a smooth transition effect
    };

    const openModal = (dayIndex) => {
        setDayIndexForNewSlot(dayIndex);
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
        setModalData({ time_from: '', time_to: '', user_id: '' }); // Reset modal data
    };

    const handleModalSubmit = () => {
        if (dayIndexForNewSlot !== null) {
            const newSlot = {
                fakeID: generateFakeId(),
                shift_date: format(weekDays[dayIndexForNewSlot], 'yyyy-MM-dd'),
                machine_id: toInt(machineId),
                ...modalData,
            };

            // Add new slot and sort by time_from
            setLocalShiftPlan((prev) => {
                const updatedPlan = [...prev, newSlot];
                return updatedPlan.sort((a, b) => a.time_from.localeCompare(b.time_from)); // Sort by time_from
            });
            
            setChangedStates(true);
            closeModal();
        }
    };

    useEffect(() => { 
        setShiftPlan(localShiftPlan);  
        console.log(localShiftPlan);
    }, [localShiftPlan]);

    const handleDeleteSlot = (slotId) => {
        setLocalShiftPlan((prev) => prev.filter((slot) => (slot.id || slot.fakeID) !== (slotId || slot.fakeID)));
        setChangedStates(true); // Mark state as changed
    };

    return (
        <React.Fragment>
            <div className="w3-section w3-mobile">
                {/* Pagination controls for previous and next week */}
                <button
                    className={`w3-button w3-theme`}
                    onClick={goToPreviousWeek}
                >
                    Vorherige Woche
                    <i className='fa fa-chevron-left w3-margin-left'></i>
                </button>
                <button
                    className={`w3-button w3-theme w3-right`}
                    onClick={goToNextWeek}
                >
                    <i className='fa fa-chevron-right w3-margin-right'></i>
                    Nächste Woche
                </button>
            </div>
            <div className="w3-row-padding w3-section" id='calendar'>
                {weekDays.map((day, index) => (
                    <div key={index} className="w3-col w3-mobile w3-center" style={widthStyle}>
                        {/* Display date and day name */}
                        <div className="w3-border">
                            <strong>{format(day, 'EEEE', { locale: de })}</strong>
                            <br/>{format(day, 'dd.MM.yyyy', { locale: de })}
                        </div>
                        {/* Time slots for each day */}
                        <div className="w3-border" style={{ marginTop: '16px' , height: '500px' , position: 'relative'}}>
                            {Array.isArray(localShiftPlan) && localShiftPlan.filter(slot => slot.shift_date === format(day, 'yyyy-MM-dd')).length > 0 ? (
                                <ul className='w3-ul'>
                                        {localShiftPlan
                                        .filter(slot => slot.shift_date === format(day, 'yyyy-MM-dd'))
                                        .map((slot, _, allSlots) => {
                                            const timeFrom = new Date(`1970-01-01T${slot.time_from}`);
                                            const timeTo = new Date(`1970-01-01T${slot.time_to}`);
                                            const startTime = new Date(`1970-01-01T05:00:00`);
                                            const totalMinutes = (new Date(`1970-01-01T24:00:00`) - startTime) / (1000 * 60);
                                            const topPosition = ((timeFrom - startTime) / (1000 * 60)) / totalMinutes * 500;
                                            const slotHeight = ((timeTo - startTime) / (1000 * 60) - (timeFrom - startTime) / (1000 * 60)) / totalMinutes * 500;

                                            const formattedTimeFrom = format(timeFrom, 'HH:mm');
                                            const formattedTimeTo = format(timeTo, 'HH:mm');
                                            const hoursDiff = differenceInHours(timeTo, timeFrom);

                                            // 1. Find overlapping slots INCLUDING the current slot
                                            const overlappingSlots = allSlots.filter(otherSlot => {
                                                if (otherSlot.shift_date !== slot.shift_date) return false;
                                                const otherTimeFrom = new Date(`1970-01-01T${otherSlot.time_from}`);
                                                const otherTimeTo = new Date(`1970-01-01T${otherSlot.time_to}`);
                                                return (timeFrom < otherTimeTo && timeTo > otherTimeFrom);
                                            });

                                            // 2. Sort overlapping slots by start time for consistent order
                                            overlappingSlots.sort((a, b) => {
                                                const aTime = new Date(`1970-01-01T${a.time_from}`);
                                                const bTime = new Date(`1970-01-01T${b.time_from}`);
                                                return aTime - bTime;
                                            });

                                            // 3. Determine overlap index and count
                                            const overlapCount = overlappingSlots.length;
                                            const overlapIndex = overlappingSlots.findIndex(s => s.id === slot.id);

                                            // 4. Calculate width and left position
                                            const slotWidth = 100 / overlapCount;
                                            const leftPosition = slotWidth * overlapIndex;

                                            return (
                                                <li 
                                                    className='w3-black w3-border w3-display-container' 
                                                    key={slot.id || slot.fakeID} 
                                                    style={{
                                                        position: 'absolute', 
                                                        top: `${topPosition}px`, 
                                                        height: `${slotHeight}px`, 
                                                        width: `${slotWidth}%`,  // Dynamically set width
                                                        left: `${leftPosition}%`, // Dynamically set position 
                                                        padding: '0px 8px',
                                                        boxSizing: 'border-box',
                                                        lineHeight: 'normal',
                                                        alignContent: 'center',
                                                        overflow: 'scroll',
                                                        scrollbarWidth: 'none'
                                                    }}
                                                >
                                                    {/* User ID Field */}
                                                    {editingField?.id === (slot.id || slot.fakeID) && editingField?.field === 'user_id' ? (
                                                        <input
                                                            type="text"
                                                            value={slot.user_id}
                                                            onChange={(e) => handleInputChange(e, slot.id || slot.fakeID, 'user_id')}
                                                            onBlur={handleEditComplete} // Save when user clicks outside
                                                            onKeyPress={handleKeyPress} // Save on Enter
                                                            autoFocus // Auto-focus on input when edit mode is triggered
                                                            className='w3-small w3-black'
                                                        />
                                                    ) : (
                                                        <span 
                                                            className='w3-small' 
                                                            onClick={() => setEditingField({ id: slot.id || slot.fakeID, field: 'user_id' })} // Enable editing on click
                                                            style={{ cursor: 'pointer' }} // Show pointer to indicate it's clickable
                                                        >
                                                            {slot.user_id}
                                                        </span>
                                                    )}
                                                    
                                                    <br />

                                                    {/* Time From Field */}
                                                    {editingField?.id === (slot.id || slot.fakeID) && editingField?.field === 'time_from' ? (
                                                        <input
                                                            type="time"
                                                            value={slot.time_from.slice(0, 5)}
                                                            onChange={(e) => handleInputChange(e, slot.id || slot.fakeID, 'time_from')}
                                                            onBlur={handleEditComplete} // Save when user clicks outside
                                                            onKeyPress={handleKeyPress} // Save on Enter
                                                            autoFocus
                                                            className='w3-small w3-black'
                                                        />
                                                    ) : (
                                                        <span 
                                                            onClick={() => setEditingField({ id: slot.id || slot.fakeID, field: 'time_from' })}
                                                            style={{ cursor: 'pointer' }}
                                                            className='w3-small'
                                                        >
                                                            {formattedTimeFrom}
                                                        </span>
                                                    )}

                                                    <span> - </span>

                                                    {/* Time To Field */}
                                                    {editingField?.id === (slot.id || slot.fakeID) && editingField?.field === 'time_to' ? (
                                                        <input
                                                            type="time"
                                                            value={slot.time_to.slice(0, 5)}
                                                            onChange={(e) => handleInputChange(e, slot.id || slot.fakeID, 'time_to')}
                                                            onBlur={handleEditComplete} // Save when user clicks outside
                                                            onKeyPress={handleKeyPress} // Save on Enter
                                                            autoFocus
                                                            className='w3-small w3-black'
                                                        />
                                                    ) : (
                                                        <span 
                                                            onClick={() => setEditingField({ id: slot.id || slot.fakeID, field: 'time_to' })}
                                                            style={{ cursor: 'pointer' }}
                                                            className='w3-small'
                                                        >
                                                            {formattedTimeTo}
                                                        </span>
                                                    )}

                                                    <br />

                                                    {/* Display hours */}
                                                    <span className='w3-small'>{`${hoursDiff} Stunden`}</span>
                                                    <button onClick={() => handleDeleteSlot(slot.id || slot.fakeID)} className="w3-button w3-display-bottomright" style={{backgroundColor: 'unset'}}>
                                                        <i className="fa fa-trash w3-text-white"></i>
                                                    </button>
                                                </li>
                                            );
                                        })}
                                </ul>
                            ) : (
                                <ul className='w3-ul w3-cell'>
                                    <li 
                                        style={{
                                            position: 'absolute', 
                                            top: `0px`, 
                                            height: `100%`, 
                                            width: '100%', 
                                            padding: '0px 8px',
                                            boxSizing: 'border-box',
                                            lineHeight: 'normal',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}
                                    >
                                        Keine Zeit vorhanden
                                    </li>
                                </ul>
                            )}
                        </div>

                        {/* Button to add a time slot */}
                        <button 
                            className='w3-button w3-theme w3-margin-top' 
                            onClick={() => openModal(index)}
                        >
                            <i className='fa fa-plus w3-margin-right w3-small'></i> Neue Zeit
                        </button>
                    </div>
                ))}
            </div>

            {/* Modal for adding new time slots */}
            {showModal && (
                <div className="w3-modal" style={{display: 'block'}}>
                    <div className="w3-modal-content w3-card-4 w3-animate-zoom" style={{width: '30%'}}>
                        <div className={`w3-container w3-theme`}>
                            <span className="w3-button w3-display-topright" onClick={closeModal}>&times;</span>
                            <h3>Neue Zeit Hinzufügen</h3>
                        </div>
                        <div className="w3-bar-block">
                            <label className='w3-bar-item w3-bar'>
                                <div className='w3-input w3-half w3-border-0'>
                                    Zeit von:
                                </div>
                                <input 
                                    type="time" 
                                    value={modalData.time_from.slice(0, 5)} 
                                    onChange={(e) => setModalData({ ...modalData, time_from: `${e.target.value}:00` })}
                                    className='w3-input w3-half'  
                                />
                            </label>
                            <label className='w3-bar-item w3-bar'>
                                <div className='w3-input w3-half w3-border-0'>
                                    Zeit bis:
                                </div>
                                <input 
                                    type="time" 
                                    value={modalData.time_to.slice(0, 5)} 
                                    onChange={(e) => setModalData({ ...modalData, time_to: `${e.target.value}:00` })}
                                    className='w3-right w3-input w3-half' 
                                />
                            </label>
                            <label className='w3-bar-item w3-bar'>
                                <div className='w3-input w3-half w3-border-0'>
                                    Benutzer:
                                </div>
                                <input 
                                    type="text" 
                                    value={modalData.user_id} 
                                    onChange={(e) => setModalData({ ...modalData, user_id: e.target.value })} 
                                    className='w3-right w3-input w3-half' 
                                />
                            </label>
                        </div>
                        <div className="w3-container">
                            <button 
                            className='w3-button w3-theme w3-margin-bottom w3-right' 
                            onClick={handleModalSubmit}
                        >
                            <i className='fa fa-save w3-margin-right w3-small'></i> Speichern
                        </button>
                        </div>
                    </div>
                </div>
            )}

        </React.Fragment>
    );
};

export { ShiftPlanCalender };
