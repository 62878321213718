import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { API, weclappShirtivalAPI } from '../../../services/apiUrl';
import useCursor from '../../utility/useCursor';
import { Modal } from '../../utility/modal';
import { ProductionPlanCalender } from './ProductionPlanCalenderForeign';
import { ProductionPlanCalenderForeign } from './ProductionPlanCalenderForeign';

const ShirtivalProductionPlan = ( {currentUser} ) => {
    
    const [loading, setLoading] = useState(false);
    const [loadingInitial, setLoadingInitial] = useState(false);
    const [message, setMessage] = useState("");

    const [customAttributes, setCustomAttributes] = useState({});

    const [productionPlace, setProductionPlace] = useState({});
    const [availableProductionPlace, setAvailableProductionPlace] = useState({});

    const [changedStates, setChangedStates] = useState(false);

    const [machine, setMachine] = useState({});
    const [availableMachines, setAvailableMachines] = useState({});
    const [availableMachineIds, setAvailableMachinesIds] = useState({});

    const [includeWeekend, setIncludeWeekend] = useState(false);

    const [initialProductionPlan, setInitialProductionPlan] = useState({});
    const [productionPlan, setProductionPlan] = useState({});

    const [initialProductionUnplanned, setInitialProductionUnplanned] = useState([]);
    const [productionUnplanned, setProductionUnplanned] = useState([]);

    const [salesChannel, setSalesChannel] = useState({});

    const resetChanges = () => {
        setLoading(true);
        setProductionPlan(initialProductionPlan);
        // console.log(initialProductionPlan)
        // console.log(initialProductionUnplanned)
        setProductionUnplanned(initialProductionUnplanned)
        setChangedStates(false);
        setLoading(false);
    };

    useEffect(() => {
        setLoading(true)
        axios.get(`${weclappShirtivalAPI}/salesChannel/activeSalesChannels`, {
            headers: {
                AuthenticationToken: currentUser.api_key,
            }
        })
        .then(response => {
            setSalesChannel(response.data.result);
        })
        .catch(error => {
            const resMessage = (error.response && error.response.data && error.response.data.detail) || error.message || error.toString();
            const mysqliError = error.response && error.response.data && error.response.data.mysqliError;
            const errorMessage = mysqliError ? `${resMessage}: ${mysqliError}` : resMessage;
            setMessage(errorMessage);
            setLoading(false);
        })
        .finally (
            setLoading(false)
        )
    }, []);

    const hasValues = (place) => {
        try {
            return Object.keys(place).length > 0; // Check if there are any keys in the object
        } catch (error) {
            return false; // Return false if there's an error
        }
    };

    const saveChanges = () => {
        
    };

    const handleWeekend = () => {
        setIncludeWeekend(prev => !prev);
    };    

    const handleMachineChange = (event) => {
        if (productionPlace.value === "Eigenproduktion") {
            const selectedMachine = JSON.parse(event.target.value); // Parse the string back into an object
            setMachine(selectedMachine); // Set the complete machine object
        }
    };

    const handleProductionPlaceChange = (event) => {
        const selectedProductionPlace = JSON.parse(event.target.value);
        setProductionPlace(selectedProductionPlace);
        setMachine({});
        setInitialProductionPlan({})
    };

    useEffect(() => {
        if (productionPlace.value === "Eigenproduktion") {
            setLoading(true);
            axios.post(`${API}shirtival/productionPlaningMachineEdit.php`, { methode: 'GET' })
            .then(response => {
                const sortedData = response.data.sort((a, b) => a.machine_id - b.machine_id);
                const modifiedSortedData = sortedData.map(machine => {
                    const matchingMachineId = availableMachineIds.find(machineId => machineId.value === machine.name);
                    if (matchingMachineId) {
                        return {
                            ...machine, // Spread existing machine properties
                            id: matchingMachineId.id // Add the id from availableMachineIds
                        };
                    }
                    return machine; // Return the original machine if no match is found
                });
                setAvailableMachines(modifiedSortedData);
                setLoading(false);
            })
            .catch(error => {
                const resMessage = (error.response && error.response.data && error.response.data.detail) || error.message || error.toString();
                const mysqliError = error.response && error.response.data && error.response.data.mysqliError;
                const errorMessage = mysqliError ? `${resMessage}: ${mysqliError}` : resMessage;
                setMessage(errorMessage);
                setLoading(false);
            });
        }
    }, [productionPlace]);

    useEffect(() => {
        setLoadingInitial(true);
        axios.get(`${weclappShirtivalAPI}/customAttributeDefinition`, {
            params: {
                ['attributeKey-in']: '[produktionsplanungOrt,produktionsplanungPresse,produktionsplanung,deadline,gesamtanzahlTextilien,produktionenErstellt,pickTextilien,pickFolien,druckAbgeschlossen,payedTime,productionPicksCompleted]',
                ['properties']: 'id,attributeKey,selectableValues.active,selectableValues.id,selectableValues.value'
            }, 
            headers: {
                AuthenticationToken: currentUser.api_key 
            }
        })
        .then(response => {
            console.log(response)
            if (response.data.result && response.data.result.length > 0) {
                const newAttributes = {};
                response.data.result.forEach(item => {
                    newAttributes[item.attributeKey] = item.id;
                });
                setCustomAttributes(prevAttributes => ({
                    ...prevAttributes,  // Spread previous attributes
                    ...newAttributes     // Add all new attributes
                }));
                const ortItem = response.data.result.find(item => item.attributeKey === 'produktionsplanungOrt');
                if (ortItem && ortItem.selectableValues) {
                    const activeSelectableValues = ortItem.selectableValues.filter(value => value.active !== false);
                    setAvailableProductionPlace(activeSelectableValues);
                }
                const presseItem = response.data.result.find(item => item.attributeKey === 'produktionsplanungPresse');
                if (presseItem && presseItem.selectableValues) {
                    const activeSelectableValues = presseItem.selectableValues.filter(value => value.active !== false);
                    setAvailableMachinesIds(activeSelectableValues);
                }
            } else {
                setMessage("Es konnten keine Produktionsorte gefunden werden.");
            }
            setLoadingInitial(false);
        })
        .catch(error => {
            const resMessage = (error.response && error.response.data && error.response.data.detail) || error.message || error.toString();
            const mysqliError = error.response && error.response.data && error.response.data.mysqliError;
            const errorMessage = mysqliError ? `${resMessage}: ${mysqliError}` : resMessage;
            setMessage(errorMessage);
            setLoadingInitial(false);
        });
    }, []);

    useEffect(() => {
        console.log(machine)
        console.log(productionPlace)
        console.log(customAttributes)
    }, [machine, productionPlace, customAttributes, availableMachines]);

    return (
        <React.Fragment>
        {message && (
            <Modal 
                message={message}
                setMessage={setMessage}
            />
        )}
        
        {loadingInitial ? (
            <div className="w3-section">Loading...</div>
        ) : ( 
            <React.Fragment>
            <div className="w3-section w3-mobile w3-bar">
                {/* Render Options with ProductionPlaces */}
                <select 
                    className='w3-quarter w3-input'
                    onChange={handleProductionPlaceChange}
                > 
                {Array.isArray(availableProductionPlace) && availableProductionPlace.length > 0 ? (
                    <React.Fragment>
                        <option value={'{}'}>Wähle einen Produktionsort aus</option>
                        {availableProductionPlace.map((productionPlace) => (
                            <option 
                            key={productionPlace.id} 
                            value={JSON.stringify({
                                id: productionPlace.id, 
                                value: productionPlace.value
                            })}
                            >
                                {productionPlace.value}
                            </option>
                        ))}
                    </React.Fragment>
                ) : (
                    ''
                )}
                </select>
                {productionPlace.value === "Eigenproduktion" && (
                    <select 
                        className='w3-quarter w3-input'
                        onChange={handleMachineChange}
                    >
                    {/* Render Options with Nachines */}
                    {Array.isArray(availableMachines) && availableMachines.length > 0 ? (
                        <React.Fragment>
                            <option value="{}">Wähle eine Maschine aus</option>
                            {availableMachines.map((machine) => (
                                <option key={machine.machine_id} value={JSON.stringify(machine)}>
                                    {machine.name}
                                </option>
                            ))}
                        </React.Fragment>
                    ) : (
                        ''
                    )}
                    </select>
                )}
                {hasValues(productionPlace) ? (
                <React.Fragment>   
                    <button 
                        className='w3-button w3-theme w3-bar-item' 
                        onClick={handleWeekend}
                        style={{
                        marginLeft: '8px'
                    }}
                    >
                        <i className={`fa ${includeWeekend ? 'fa-eye-slash' : 'fa-eye'} w3-margin-right`}></i> Wochenende
                    </button>
                </React.Fragment> 
                ) : ( 
                    ''
                ) }
                {changedStates && (
                <React.Fragment>
                <button 
                    className={`w3-button ${loading ? 'w3-button-loading' : 'w3-theme'} w3-right w3-bar-item`} 
                    style={{
                        pointerEvents: loading ? 'none' : 'auto'
                    }}
                    onClick={saveChanges}
                >
                    {!loading ? (
                        <>
                            <i className='fa fa-save w3-margin-right w3-small'></i>
                        </>
                    ) : (
                        <>
                            <i className='fa fa-spinner fa-spin w3-margin-right w3-small'></i>
                        </>
                    )}
                    Speichern
                </button>
                <button 
                    className={`w3-button ${loading ? 'w3-button-loading' : 'w3-theme'} w3-right w3-bar-item`} 
                    style={{
                        pointerEvents: loading ? 'none' : 'auto',
                        marginRight: '8px'
                    }}
                    onClick={resetChanges}
                >
                    {!loading ? (
                        <>
                            <i className='fa fa-save w3-margin-right w3-small'></i>
                        </>
                    ) : (
                        <>
                            <i className='fa fa-spinner fa-spin w3-margin-right w3-small'></i>
                        </>
                    )}
                    Zurücksetzen
                </button>
                </React.Fragment>
                )}     
            </div>
            {hasValues(productionPlace) && hasValues(customAttributes) && productionPlace.value !== 'Eigenproduktion' ? (
                <ProductionPlanCalenderForeign 
                    currentUser={currentUser}
                    includeWeekend={includeWeekend}
                    setChangedStates={setChangedStates}
                    message={message}
                    setMessage={setMessage}
                    salesChannel={salesChannel}
                    productionPlan={productionPlan}
                    setProductionPlan={setProductionPlan}
                    productionUnplanned={productionUnplanned}
                    setProductionUnplanned={setProductionUnplanned}
                    initialProductionPlan={initialProductionPlan}
                    setInitialProductionPlan={setInitialProductionPlan}
                    initialProductionUnplanned={initialProductionUnplanned}
                    setInitialProductionUnplanned={setInitialProductionUnplanned}
                    customAttributes={customAttributes}
                    productionPlace={productionPlace}
                    machine={machine}
                />   
            ) : (
                <React.Fragment>

                </React.Fragment>
            )}
            </React.Fragment>     
        )} 
        </React.Fragment>
    );
};

export { ShirtivalProductionPlan };


